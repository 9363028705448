/* @import url("./bootstrap.min.css"); */
/* @import url("../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css"); */

#tinymce {
  padding: 0.375rem 0.75rem;
}

.content-list {
  margin: 0.5rem 0;
}
.content-list .titlecircle,
.content-list .titlecircle a {
  font-weight: 700;
  font-size: 1rem;
  color: #0062b3;
  display: flex;
  margin-bottom: 0.5rem;
}
.content-list .titlecircle h6 {
  margin: auto 0;
  font-weight: 900;
}
.content-list .titlecircle .circle {
  margin: 0 10px 0 0;
  border-radius: 40px;
  border: none;
  background-color: #0062b3;
  line-height: 30px;
  width: 30px;
  height: 30px;
  text-decoration: none;
  display: table;
  text-align: center;
  cursor: pointer;
  color: #fff;
  min-width: 30px;
}
.content-list .titlecircle .circle i {
  padding: 3px 8px 0 0;
}
.content-list ul {
  margin: 0;
  padding: 5px 7px;
}
.content-list ul li {
  display: flex;
}
.content-list ul li i {
  padding: 3px 8px 0 0;
}
.content-list ul li p {
  padding: 0;
  margin-bottom: 0.5rem;
}

ul.content-list {
  margin: 0;
  padding-left: 0;
}
ul.content-list li {
  display: block;
}
ul.content-list li i {
  padding: 3px 8px 0 0;
}
ul.content-list li p {
  padding: 0;
  margin-bottom: 0.5rem;
}

ul.content-list > li::before {
  content: "\f058";
  color: #17a2b8 !important;
  font-weight: 900;
  font-family: "Font Awesome 6 Free"; /*Font Awesome\5 Free*/
  padding-right: 8px;
}

.title-top {
  color: #2c3e50;
  border-bottom: 3px solid #5ec6cb;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.section-two {
  background-color: rgba(236, 233, 233, 0.478);
}
.titlecircle,
.titlecircle a {
  font-weight: 700;
  color: #0062b3;
  display: flex;
  margin-bottom: 0.5rem;
  line-height: 1.5;
}
.ncircle {
  margin: 0 10px 0 0;
  border-radius: 40px;
  border: none;
  background-color: #0062b3;
  line-height: 30px;
  width: 30px;
  height: 30px;
  text-decoration: none;
  display: table;
  text-align: center;
  cursor: pointer;
  color: #fff;
  min-width: 30px;
}

h2.titlecircle .ncircle {
  line-height: 27px;
  width: 27px;
  height: 27px;
  min-width: 27px;
}

h1,
h1.titlecircle,
h1.title-top {
  font-size: 20px;
}

h2,
h2.titlecircle,
h2.title-top {
  font-size: 18px;
}

h3,
h3.titlecircle,
h3.title-top {
  font-size: 16px;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 0px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
  box-shadow: 1px 1px 7px 3px rgb(0 0 0 / 8%);
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote::after {
  color: #ccc;
  content: close-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-left: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}

.paragraph {
  background: #eeeeee;
  padding: 10px 12px !important;
  border-left: 4px solid #009bdc;
  margin-left: -18px;
}

.list {
  list-style: none;
}

.modal {
  z-index: 1200;
}

.act-doctor img {
  max-width: 100%;
}
