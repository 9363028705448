/*------------------------ Trang Home ------------------------*/
/*dùng cho toàn trang*/
html,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
label,
span,
body {
  font-family: Roboto, Arial, Tahoma, Helvetica, sans-serif;
}
a {
  color: #007eb2;
}
a:hover {
  color: #009bdc;
}
.form-control:focus {
  box-shadow: none !important;
}
.btn {
  font-size: 14px !important;
}
button:focus {
  box-shadow: none !important;
  outline: none !important;
}
.btn-contact {
  background-color: #007eb2 !important;
  color: #fff !important;
  padding: 3px !important;
  box-shadow: none !important;
  border-radius: 1rem !important;
  width: 10rem;
}
.btn-contact:hover {
  background: #0883b7 !important;
  color: #fff !important;
  cursor: pointer;
  transition: 0.5s;
}
/*nút đặt lịch khám*/
.btn-booking {
  background-color: #0062b3 !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 1rem !important;
  padding: 5px 25px !important;
}
.btn-booking:hover {
  background: #0a4263 !important;
  color: #fff !important;
  cursor: pointer;
  transition: 0.5s;
}
/*tìm hiểu thêm*/
.btn-viewmore {
  background-color: #007eb2 !important;
  color: #fff !important;
  padding: 5px 25px !important;
  box-shadow: none !important;
  border-radius: 1rem !important;
}
.btn-viewmore:hover {
  background: #0883b7 !important;
  color: #fff !important;
  cursor: pointer;
  transition: 0.5s;
}
section p {
  text-align: justify;
}
.title-top {
  color: #2c3e50;
  border-bottom: 3px solid #5ec6cb;
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.news-top {
  padding: 0 !important;
  margin: 0;
  border-bottom: 1px solid #007eb2;
  display: inline-flex;
}
.news-top h4 {
  padding: 3px 10px;
  margin-bottom: 0;
  background: #0062b3;
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
}
/*background tiêu đê*/
.page-background-tt {
  background-image: url("/assets/images/background-top/backnews.webp") !important;
  background-size: cover;
  width: 100%;
  height: 10rem;
  align-content: center !important;
  position: relative;
}
/*thẻ tiêu đề*/
.page-title-top,
.page-title-top-double {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.page-title-top h1,
.page-title-top-double h1 {
  color: #4d4d4d !important;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
}
.page-background-tt .department-tile-top {
  color: #007eb2 !important;
  font-weight: bold;
  margin-top: -10px !important;
  text-transform: uppercase;
}
.page-background-tt hr {
  width: 15rem;
  border: 1px solid #91d8db;
  margin-top: 4px;
}
.about-details h4,
.about-details .content {
  color: #4d4d4d;
}
.about,
.skill,
.package,
.blog,
.doctor-related,
.about-details,
.services,
.service-gg,
.booking-detail,
.vaccination,
.pharmacy,
.security,
.term-condition,
.news,
.answer,
.not-page,
.special,
.contact,
.packages-detail,
.recruitment,
.recruitment-detail,
.stepper,
.payed,
.box-mobile {
  /* padding-top: 1.5rem; */
  padding-bottom: 1.5rem;
}
img {
  max-width: 100%;
}
/*------Security & Term Condition ---*/
.term-condition .content,
.security .content {
  margin-bottom: 1rem;
}
.term-condition .content .titlecircle,
.security .content .titlecircle {
  font-weight: 700;
  font-size: 1rem;
  color: #0062b3;
  display: flex;
  margin-bottom: 0.5rem;
}
.term-condition .content .titlecircle h6,
.security .content .titlecircle h6 {
  margin: auto 0;
  font-weight: 900;
  text-align: justify;
}
.term-condition .content .titlecircle .circle,
.security .content .titlecircle .circle {
  margin: 0 10px 0 0;
  border-radius: 40px;
  border: none;
  background-color: #0062b3;
  line-height: 30px;
  width: 30px;
  height: 30px;
  text-decoration: none;
  display: table;
  text-align: center;
  cursor: pointer;
  color: #fff;
  min-width: 30px;
}
.term-condition .content ul,
.security .content ul {
  list-style-type: none;
  text-align: justify;
}
.term-condition .content li,
.security .content li {
  margin-bottom: 0.5rem;
}
.term-condition .content p,
.security .content p {
  padding-left: 40px;
  margin-bottom: 0.5rem;
}
/*app nav -menu*/
.menu-nav {
  display: block;
}
/*H6: Tên của bác sĩ*/
.doctor-related .doctor-name {
  color: #007eb2 !important;
}
.doctor-related h6 {
  font-weight: bold !important;
  font-size: 14px;
  margin: 0;
}
/****Bác sĩ chi tiết****/
.doctor-related {
  background-repeat: no-repeat;
  background-size: cover;
}
.doctor-related .category {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
  border: none;
  border-radius: 7px;
}
/*H6: Title của bác sĩ*/
.doctor-related h3 {
  font-size: 15px;
  margin-bottom: 0;
}
.doctor-related .doctor-level {
  color: #0062b3 !important;
  display: block;
  font-size: 14px;
}
.doctor-related .doctor-title {
  border-bottom: 2px solid #5ec6cb !important;
  color: #5ec6cb !important;
  min-height: 2.6rem;
  justify-content: center !important;
}
.doctor-related .doctor-title a {
  text-decoration: none;
}
.doctor-related .category img {
  /* min-height: 205px; */
}
.doctor-related .content {
  /* margin: 0;
    padding-top: .5rem;
    min-height: 4.2rem;
    text-align: justify;
    font-size: 16px !important; */
  padding-top: 0.5rem;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  min-height: 93px;
  font-size: 14px;
}
/*-----package-------*/
.package .category {
  background: #fff;
  padding: 10px !important;
  border: none;
  border-radius: 5px;
}
.package .category .row {
  height: 100%;
}
.package .category .image {
  height: 100%;
  padding-right: 0px;
}
.package .category .image img {
  height: 100%;
  object-fit: cover;
  object-position: left;
}

/**New**/
.news .category .row {
  height: 100%;
}
.news .category .image {
  height: 100%;
  padding-right: 0px;
}
.news .category .image img {
  height: auto;
  object-fit: cover;
  object-position: left;
}
.card-block .card .col-image {
  padding-right: 0px;
}
@media only screen and (max-width: 991px) {
  .card-block .card .col-image {
    padding-right: 15px;
  }
  img {
    width: 100%;
    height: auto !important;
  }
}

@media only screen and (max-width: 768px) {
  .card-block .card .col-image {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 426px) {
  .card-block .card .col-image {
    padding-right: 15px;
  }
  .package .category {
    min-height: 370px;
  }
}

.package .discount {
  position: absolute !important;
  top: -3px;
  left: 1.5rem !important;
  pointer-events: none;
}
.package .discount img {
  width: 40%;
}
.package .title a {
  color: #0062b3 !important;
  text-decoration: none;
}
.package .title a:hover {
  color: #06b1f9 !important;
}
.package .title a .h3,
.package .title a h3 {
  font-size: 15px;
  line-height: 22px;
  font-weight: bold;
  text-transform: uppercase;
}
.package .title .price,
.package .title .price .more {
  float: right;
}
.package .listPrice {
  text-decoration-line: line-through;
  color: #424242;
  margin: auto 1rem auto 0;
  font-weight: bold !important;
}
.package .salePrice {
  color: #ce0303;
  margin: auto 0;
  font-weight: bold !important;
}
.package .Price-cost {
  color: #ce0303;
  margin: auto 0;
  font-weight: bold !important;
}
.package .title label {
  font-size: 14px;
  font-weight: bold;
}
.timeDate {
  color: #337ab7;
  font-size: 12px !important;
  font-weight: bold;
  padding: 0;
}
/*BacktoTop*/
.backTop {
  display: none;
}
.backTop img {
  position: fixed;
  width: 40px;
  bottom: 18%;
  right: 5px;
  z-index: 1000;
}
.message {
  position: fixed;
  z-index: 999;
  bottom: 2%;
  width: 55px;
}
.ck-editor__editable_inline {
  min-height: 100px;
  border: 1px solid #e6e9ec !important;
}
.mb-0 > .form-group {
  margin-bottom: 0;
}
.novalid-icon .form-group .form-control.ng-invalid,
.novalid-icon .form-group .form-control.ng-valid {
  background-image: none !important;
  padding-right: 5px !important;
}
ngx-recaptcha2.is-invalid iframe {
  border: 1px solid #e80000;
}
#paymentLayer {
  z-index: 9999;
}
.scrollbar.style-3::-webkit-scrollbar {
  width: 12px;
  background-color: #f6f5f5;
}
.scrollbar.style-3::-webkit-scrollbar-thumb {
  background-color: rgb(0 155 220 / 57%);
}
a.link {
  color: #0062b3 !important;
  text-decoration: none;
}
a.link:hover {
  color: #06b1f9 !important;
  text-decoration: none;
}

/* Begin Loadding Giống facebook */
.timeline-item {
  background: #fff;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: 3px;
  padding: 12px;
  margin: 0 auto;
  max-width: 100%;
  min-height: 175px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 150px;
  position: relative;
}

.background-masker {
  background: #fff;
  position: absolute;
}
.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  left: 140px;
  right: 0;
  height: 10px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: 10px;
  left: 140px;
  height: 8px;
  width: 10px;
}

.background-masker.header-bottom {
  top: 18px;
  height: 6px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
  top: 24px;
  height: 6px;
}

.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: 300px;
  right: 0;
}

.background-masker.subheader-right {
  left: 230px;
}

.background-masker.subheader-bottom {
  top: 30px;
  height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end,
.background-masker.content-first-start,
.background-masker.content-fourth-line,
.background-masker.content-fifth-line,
.background-masker.content-sixth-line {
  top: 40px;
  left: 140px;
  right: 0;
  height: 6px;
}
.background-masker.content-first-start {
  width: 10px;
  top: 60px;
  height: 90px;
}
.background-masker.content-fourth-line {
  top: 95px;
  height: 7px;
}
.background-masker.content-fifth-line {
  top: 109px;
  height: 16px;
}
.background-masker.content-sixth-line {
  height: 25px;
  left: 249px;
  top: 125px;
}

.background-masker.content-top {
  height: 20px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
  width: auto;
  left: 380px;
  right: 0;
  top: 60px;
  height: 8px;
}

.background-masker.content-second-line {
  top: 68px;
}

.background-masker.content-second-end {
  left: 420px;
  top: 74px;
}

.background-masker.content-third-line {
  top: 82px;
}

.background-masker.content-third-end {
  left: 300px;
  top: 88px;
}

.btn-primary {
  background-color: #0064b0 !important;
  border-color: #0064b0 !important;
}
.btn-primary:hover {
  background-color: #0777cd !important;
  border-color: #0777cd !important;
}
