/* You can add global styles to this file, and also import other style files */
html,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
label,
span,
body {
  font-family: Roboto, Arial, Tahoma, Helvetica, sans-serif;
}

.table-fix-head {
  overflow-y: auto;
  max-height: calc(100vh - 278px);
  margin: 2px;
  border: none;
  border-top: none;
  border-bottom: none;
  border-right: none;
  box-shadow: 1px 1px 10px 1px rgb(0 0 0 / 10%);
  & > table {
    width: 100%;
    border: none !important;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    & > thead {
      position: sticky;
      top: 0px;
      z-index: 1;
      background: #fff;
      box-shadow: 1px 1px 10px 1px rgba(3, 82, 131, 0.07);
      tr {
        th {
          border: none;
          border-right: 1px solid rgb(108 117 125 / 28%);
        }
      }
    }
    & > tbody {
      tr {
        &:hover {
          background-color: rgb(234 244 254);
          cursor: pointer;
        }
        td {
          border: none;
          border-right: 1px solid rgb(108 117 125 / 15%);
        }
      }
    }
  }
}
a {
  text-decoration: none;
}

.breadcrumb {
  .breadcrumb-item + .breadcrumb-item:before {
    padding-right: 5px;
  }
}

.select-control {
  position: relative;
  .form-control {
    padding: 5px 10px;
  }
  .select-result {
    position: absolute;
    top: 37px;
    width: 100%;
    background: #fff;
    box-shadow: 1px 0px 11px 1px rgb(0 0 0 / 16%);
    border-radius: 4px;
    padding: 5px;
    overflow: hidden;
    overflow-y: scroll;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        padding: 5px;
        &:hover {
          background-color: #f1f1f1;
          cursor: pointer;
        }
      }
    }
  }
}

ngb-datepicker {
  .ngb-dp-day {
    &.disabled {
      cursor: not-allowed;
      pointer-events: unset;
      .text-muted {
        color: red !important;
        cursor: not-allowed;
      }
    }
  }
}
