/*-----------------------------------responsive-----------------------------------*/
@media (max-width: 330px) {
  /*slides*/
  .slide .overlay {
    top: 5%
  }

    .slide .overlay h1 {
      font-size: 13px;
    }

    .slide .overlay h4,
    .slide .overlay a {
      font-size: 12px;
    }
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  /*all*/

  .box-mobile .block:nth-child(1) .block-content, .box-mobile .block:nth-child(4) .block-content {
    background-color: #0064b0 !important;
  }

  .box-mobile .block:nth-child(2) .block-content, .box-mobile .block:nth-child(3) .block-content {
    background-color: #009bdc !important;
  }

  .slide .overlay-mobile .description h1 {
    font-size: 1rem !important;
  }

  .slide .overlay-mobile .description h4 {
    font-size: 0.6rem !important;
  }

  /*menu*/
  /* .navbar .container {
    display: none !important;
  } */

  .navbar .img-fluid img {
    width: 45% !important;
    height: auto;
  }

  /*booking vs about*/
  .booking .book h4, .about h4 {
    text-align: center;
  }

  .booking .form-control {
    width: 100%;
  }

  .booking .time {
    display: none;
  }
  /*team*/
  .team figure {
    padding: 1rem;
  }
  /*about*/
  .about .img-mobile {
    display: block;
    padding-bottom: .5rem;
  }

  .about .img-pc {
    display: none;
  }

  .about .bt-rs {
    text-align: center;
  }
  /*counter*/
  .counter {
    display: none;
  }

  /*footer*/
  .page-footer .logo-footer img {
    width: 40% !important;
  }

  .page-footer h4 {
    padding-top: 3rem;
  }

  /*calendars*/
  .booking-content button {
    position: static;
  }

  .booking-content p {
    position: relative;
    left: -1rem;
  }

  .bussiness .sectionFive .bgForm .footer a {
    margin: 0 !important;
  }

  .price .mobile .number {
    padding-right: 2% !important;
  }


  /*shopping*/

  .stepper {
    padding-bottom: 0;
  }

  .package .number-input {
    width: 60%;
  }

  .progressbar p {
    display: none;
  }

  .title-mobile {
    display: block !important;
  }
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {

  /*menu*/
  /* .navbar .container {
    display: none !important;
  } */
  /*calendar*/
  .booking .time {
    display: none;
  }

  .booking .form-control {
    width: 100%;
  }

  /*slide*/
  .slide .overlay {
    top: 25%;
  }

    .slide .overlay h1 {
      font-size: 1.6rem;
    }

    .slide .overlay h4 {
      font-size: 1rem;
    }

    .slide .overlay a {
      font-size: 14px;
    }

    .slide .overlay p {
      display: none;
    }

  /*about*/
  .about-content .left, .about-content .right {
    padding: 0;
  }

  .about .img-mobile {
    display: block;
    padding: 5px;
  }

  .about .hide {
    display: none;
  }

  /*skill*/
  .skill .card-footer {
    text-align: center;
  }

  .skill .col-lg-3.col-md-6 {
    padding: 2rem;
  }

  /*team*/
  .team figure {
    padding: 1rem;
  }

  /*footer*/
  .page-footer .logo-footer img {
    width: 50% !important;
  }

  .page-footer h4 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 0;
  }

  /*--- BẢNG GIÁ ---*/
  .price .mobile .number {
    padding-right: 5% !important;
  }

  /* title backgroup*/
  .department-tile-top {
    color: #0E83D4 !important;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 5px !important;
  }

  .doctor-title-top {
    color: #1D1D20 !important;
    font-size: 0.8rem;
    font-weight: bold;
  }

  /*shopping*/
  .package .number-input {
    width: 30%;
  }
}

/*Small devices (768px)*/
@media (max-width: 767.98px) {

  #dvCall {
    display: block !important;
  }

  .title-top {
    /* border-bottom: none !important; */
  }

  .title-top,
  .news-top h4 {
    font-size: 18px;
  }

  /*menu*/
  .header .navbar-header .language {
    display: none;
  }

  /* vaccine*/
  .vaccination {
    background: none;
  }

  /*booking*/

  .blog .category .title h3 {
    min-height: 1rem;
  }

  /*ABOUT*/
  .about-details .about-content .order {
    order: 1;
  }

  .about-details .about-content .left,
  .about-details .about-content .right {
    padding: 10px 0 !important;
  }

  .about .button {
    text-align: center;
  }

  .bg-counter {
    display: none;
  }
  /*shopping*/

  .shopping.prevent,
  .shopping .prevent,
  .packages-bg .prevent {
    display: none !important;
  }

  .mergin h4 {
    font-size: 14px;
  }

  .packages-bg .trash {
    display: none !important;
  }

  .packages-bg .btn-price {
    display: none !important;
  }

  .packages-bg .right-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .packages-detail .title-name {
    margin-left: 0 !important;
  }

  .packages-detail .left {
    padding: 0;
  }
  /*--- Shoppinng ---*/
  .package .applydate {
    display: none;
  }

  .mergin .form-control {
    text-align-last: right;
  }

  .mergin .sale-mergin {
    padding: 0 15px !important;
    display: block !important;
    text-align: right !important;
  }
  /*--- GIỎ HÀNG ---*/
  .packages-bg .footer .btn-pay,
  .packages-bg .footer .btn-remove,
  .packages-bg .footer .btn-back {
    margin: .5rem !important;
  }

  /*pay*/
  .pay .totalpay h5 {
    float: right;
  }

  .pay .shopping {
    border-bottom: none !important;
  }

  .payment .note p {
    margin-left: 0 !important;
  }

  .payment .note ul,
  .payment .note3 ul,
  .payment .note3 .name {
    padding-left: 0 !important;
  }

  .pay .totalpay .totalsum {
    padding-right: 15px !important;
  }
  /*services*/
  .special-detail-end .special-detail-content .mobile {
    display: block;
  }

  .special-detail-end .special-detail-content img {
    display: none;
  }

  .special-detail-end .content ul,
  .special-detail-top .shortDescription ul {
    padding-left: 7px !important;
  }

  .special-detail-end .content p,
  .special-detail-end .content .circle-content {
    padding-left: 7px !important;
  }
  /*--- Vaccine ---*/
  .vaccin .mobile {
    display: block !important;
  }

  .vaccin .desktop {
    display: none !important;
  }

  /*--- Chính sách ---*/
  .term-condition .content ul,
  .security .content ul {
    padding-left: 7px;
  }

  .term-condition .content p,
  .security .content p {
    padding-left: 7px;
  }

  /*--- Doanh nghiệp ---*/
  .bussiness .sectionTwo .imagePC {
    display: none !important;
  }

  .bussiness .sectionTwo .imageMobile {
    display: block !important;
  }

  .bussiness .content ul,
  .insurrance .content ul {
    padding-left: 7px;
  }

  .bussiness p,
  .bussiness .circle-content,
  .insurrance p,
  .insurrance .circle-content {
    padding: 0 !important;
  }

  .insurrance .sectionOne .content ul li i,
  .bussiness .sectionOne .content ul li i {
    padding-right: 15px !important;
  }
  /*--- Tuyển dụng ---*/
  .job-item .description .recruitment-content-text ul {
    padding-left: 7px !important;
  }

  .recruitment-detail .content p,
  .recruitment-detail .content .circle-content {
    padding: 0 !important;
  }


  /*--- TIN TỨC ---*/
  .newsdetail-content .newsdetail-content-text ul li .list-li {
    padding-left: 0 !important;
  }

  /*--- GÓI KHÁM CHI TIẾT ---*/

  .packages-detail .md-number-input.number-input {
    margin: auto;
  }

  .packages-detail .btn-proces {
    text-align: center;
  }

  
}

/*Medium devices (down 992px) */
@media(max-width: 991.98px) {
  /*  home - slide*/

  .slide .carousel-caption {
    bottom: 30% !important;
  }

  .slide .overlay-mobile .description {
    background: none !important;
    box-shadow: none !important;
    opacity: 1 !important;
    padding: 0 !important;
    text-align: right !important;
  }


    .slide .overlay-mobile .description p {
      display: none !important;
    }

  .container {
    max-width: 1024px !important;
  }

  .news-top {
    border: none !important;
  }
  /*trang home*/

  .header .top {
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .header .sidebar.active {
    left: 0 !important;
  }

  .header .overlay.active {
    display: block !important;
    opacity: 1 !important;
  }

  .header .show-menu {
    display: block !important;
    padding-top: 5%;
    padding-bottom: 5%;
    text-align: end;
  }

  .header .bookmobile {
    display: block !important;
    position: relative;
    background: #009BDC;
    color: #fff;
    text-align: center;
    padding: 10px 0;
  }

  .page-footer h4 {
    padding-top: 1rem;
  }

  .header .navbar-header .menu,
  .header .navbar-header .number-phone,
  .header .navbar-header .language {
    display: none !important;
  }

  .navbar-header .menu .link-active .nav-link {
    color: none !important;
    background-color: none;
  }

  .dropdown-item:hover,
  li .link-active > .dropdown-item,
  .nav-link:hover {
    background-color: transparent !important;
  }

  .navbar-slidemenu .link-active {
    background-color: #0064b0;
  }
  /*popup*/
  .jw-modal-body {
    width: 95% !important;
  }

  /*shopping*/
  .packages-bg .name h6 {
    font-size: 13px;
    line-height: 18px !important;
  }

  .mergin .form-control {
    font-size: 15px;
  }

  .mergin h4 {
    font-size: 17px;
  }

  .packages-bg .package,
  .packages-bg .mergin {
    margin: 0;
  }

  .packages-bg .sale-right h6,
  .packages-bg .sale h6 {
    font-size: 12px;
  }

  .packages-bg .icon-trash .fas {
    font-size: 20px !important;
  }

  .footer-bg .footer {
    text-align: center !important;
  }

  /*pay*/
  .payed .check p {
    padding: .5rem 0!important;
  }

  /*đội ngũ bác sĩ*/
  .doctor .desktop1,
  .doctor .desktop2 {
    padding: 1rem !important;
  }

  /*doctor details*/
  .doctor-page .hide {
    display: none;
  }
  /*doctor details*/
  .doctor-content {
    background-image: none !important;
    padding: 1rem 0 !important;
  }

  /*--- Doctor Details Mobile ---*/
  .doctor-detail .accordion {
    display: block !important;
  }

  .doctor-detail .experience {
    display: none !important;
  }

  /*--- Package Details Mobile ---*/
  .packages-detail .accordion {
    display: block !important;
  }

  .packages-detail .experience {
    display: none !important;
  }

  .packages-detail .title-name h4 {
    font-size: 17px !important;
  }


  /*--- BẢNG GIÁ ---*/

  .price .mobile {
    display: block !important;
  }

    .price .mobile .number {
      padding-right: 6%;
    }

  .price .desktop {
    display: none !important;
  }

  /*--- TUYỂN DỤNG ---*/
  .recruitment .job-item .label {
    display: block !important;
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
  /*nav menu*/
  .navbar .row {
    width: 100%;
  }

  .navbar-brand {
    padding-left: 0 !important;
    width: 80% !important;
  }

  /* .navbar .container {
    display: none !important;
  } */

  .logo-menu img {
    width: 230%;
  }

  /*-- HOME -- Slide*/
  .carousel-inner .overlay {
    padding-left: 3rem !important;
  }

  /*calendar*/
  .booking .time {
    border: 0 !important;
    padding-bottom: 2rem;
  }
  /*-- HOME -- Blog*/

  .blog .category .title h3 {
    min-height: 2rem;
  }

  /*shopping*/
  .packages-bg .mobile,
  .packages-bg .mobile_n {
    display: none;
  }
  /*services*/
  .special-detail-end .special-detail-content .mobile {
    display: none;
  }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {

  /*menu*/
  .menu-button {
    display: none;
  }

  .navbar-slidemenu {
    display: none;
  }

  .header .top {
    padding-top: 1%;
    padding-bottom: 1%
  }

  .header .bookmobile {
    display: none;
  }

  /*shopping*/
  .packages-bg .mobile,
  .packages-bg .mobile_n {
    display: none;
  }

  .mergin .sale-mergin h5 {
    font-size: 19px !important;
  }
  .md-number-input.number-input input[type=number] {
    width: 100% !important;
  }
  /*services*/
  .special-detail-end .special-detail-content .mobile {
    display: none;
  }
}

/*Large devices */
@media (min-width: 992px) and (max-width: 1199px) {
  .news-top h4 {
    font-size: 21px;
  }

  .header .language .select {
    padding-left: 0 !important;
  }

    .header .language .select .select-ul {
      left: -15px!important;
      width: 155%!important;
    }
   /*pay*/
  .payed .check p {
    padding: .5rem 0!important;
  }
}

@media (max-width: 992px) {
  .slide ol {
    left: 0 !important;
  }

  .slide .overlay {
    display: none;
  }

  .slide .overlay-mobile {
    display: block !important;
  }

  .slide .carousel-caption {
    bottom: 20%;
  }

  .slide .overlay-mobile .description h1 {
    font-size: 1.5rem;
  }

  .slide .overlay-mobile .description h4 {
    font-size: 1rem;
  }

  .slide .overlay-mobile .description p {
    font-size: 14px;
  }

  .box-mobile {
    display: block !important;
  }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  /*container*/



  /*shopping*/
  .packages-bg .mobile {
    display: none;
  }
  /*services*/
  .special-detail-end .special-detail-content .mobile {
    display: none;
  }
}
